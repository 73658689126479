import { createTheme } from "@mui/material";

export const appTheme = createTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    h5: {
      fontWeight: 700,
    },
    body1: {
      fontSize: "0.9rem",
    },
    button: { textTransform: "none" },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#674A9B",
    },
    secondary: {
      main: "#680504",
    },
    custom: {
      main: "#fffff",
    },
  },
});
