import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/system";
import { appTheme } from "./utils/theme";
import Home from "./pages/Home";
import HowItWorks from "./pages/HowItWorks";
import Agencies from "./pages/Agencies";
import AppLayout from "./layout/AppLayout";

const App = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <Router>
        <Routes>
          {/* Home page */}
          <Route element={<AppLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/acceuil" element={<Home />} />
            {/* How it works page */}
            <Route path="/comment-ça-marche" element={<HowItWorks />} />
            {/* Nos agences */}
            <Route path="/nos-agences" element={<Agencies />} />
            <Route path="/contact" element={<Agencies />} />
            {/* Catch them all */}
            <Route path="/*" element={<>404</>} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
