import { Button, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { motion } from "framer-motion";

import {
  AccountsBack,
  AppStore,
  Boutique,
  CceidLogo,
  Comment,
  Credit,
  CreditIcon,
  Epargne,
  EpargneIcon,
  FriklabelLogo,
  Hero,
  JenyLogo,
  PlayStore,
  PosteLogo,
  ServicePlus,
  Tontine,
} from "../assets/img";
import HomeCard from "../components/HomeCard/HomeCard";
import DoneIcon from "@mui/icons-material/Done";
import { openExternal } from "../utils/openExternal";
import FadeIn from "../utils/fade";

const cardsData = [
  {
    withColor: true,
    title: "Formalisation",
    text: "Rendre formel les acteurs informels de la micro finance en leur donnant  le statut d’entrepreneurs de la finance digitale ",
  },
  {
    title: "Réorientation",
    text: "Réorienter  la politique de collecte d’Epargne et d’octroi de crédit en utilisant les nouveaux de paiements mobiles ",
  },
  {
    title: "Inclusion financiere",
    text: "Faciliter l’inclusion financière en rendant les services bancaires accessibles aux couches les plus défavorisées ",
  },
];

const Home = () => {
  return (
    <>
      {/* Hero section */}
      <Box
        sx={{
          marginTop: 2,
          width: "100%",
          backgroundImage: `url("${Hero}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          minHeight: "602px",
          display: "flex",
          alignItems: "center",
          padding: { xs: 3, sm: 5 },
          position: "relative",
        }}
      >
        <Stack
          sx={{ maxWidth: { xs: "300px", sm: "400px" }, color: "white" }}
          spacing={3}
        >
          <Typography
            sx={{
              fontSize: { xs: "18px", sm: "20px", md: "30px" },
              fontWeight: 700,
            }}
          >
            Bienvenue sur Finanfa , votre plateforme intégrée de services
            digitaux.
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            Tous vos services financiers et non financiers digitaux réunis en un
            seul endroit. Désormais tout est à votre portée. Économisez du
            temps, de l’argent grâce à cette application.
          </Typography>

          <Stack direction="row" spacing={2}>
            {/* Button playStore */}
            <Box
              sx={{
                width: { xs: "111px", sm: "153px" },
                height: { xs: "34px", sm: "53px" },
                borderRadius: "5px",
                backgroundColor: "white",
                cursor: "pointer",
                padding: 1,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => {
                openExternal(
                  "https://play.google.com/store/apps/details?id=solutions.hardsoft.finanfa"
                );
              }}
            >
              <img
                src={PlayStore}
                alt="btn-playstore"
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "111px", sm: "153px" },
                height: { xs: "34px", sm: "53px" },
                borderRadius: "5px",
                backgroundColor: "white",
                cursor: "pointer",
                padding: 1,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              onClick={() => {
                openExternal(
                  "https://play.google.com/store/apps/details?id=solutions.hardsoft.finanfa"
                );
              }}
            >
              <img
                src={AppStore}
                alt="btn-playstore"
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
              />
            </Box>
          </Stack>

          {/* Floating button */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          >
            <Stack
              direction="row"
              sx={{
                display: { xs: "none", sm: "flex" },
                width: "fit-content",
                borderRadius: "5px",
                backgroundColor: "white",
                cursor: "pointer",
                color: "secondary.main",
                paddingY: 1,
                paddingX: 2,
                right: { sm: "40px", md: "150px" },
                position: "absolute",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              alignItems="center"
              spacing={1}
            >
              <img src={EpargneIcon} alt="epargne-icon" />
              <Typography sx={{ fontWeight: 600 }}>Epargne</Typography>
            </Stack>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            <Stack
              direction="row"
              sx={{
                display: { xs: "none", sm: "flex" },
                width: "fit-content",
                borderRadius: "5px",
                backgroundColor: "white",
                cursor: "pointer",
                color: "secondary.main",
                paddingY: 1,
                paddingX: 2,
                position: "absolute",
                right: { sm: "50px", md: "200px" },
                bottom: { sm: "190px", md: "190px" },
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              alignItems="center"
              spacing={1}
            >
              <img src={CreditIcon} alt="epargne-icon" />
              <Typography sx={{ fontWeight: 600 }}>Credit</Typography>
            </Stack>
          </motion.div>
        </Stack>
      </Box>

      {/* Cards */}
      <Box
        sx={{
          marginTop: 4,
          display: "grid",
          justifyItems: "center",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: 2,
        }}
      >
        {cardsData.map((el, index) => {
          return <HomeCard {...el} key={index} />;
        })}
      </Box>

      {/* Que pouvez-vous faire */}
      <>
        <Stack alignItems={"center"} sx={{ marginTop: 4 }} spacing={1}>
          <Typography
            color="primary"
            sx={{
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            Que pouvez-vous faire avec Finanfa ?
          </Typography>
          <Box
            sx={{
              width: "49px",
              height: "2px",
              backgroundColor: "primary.main",
            }}
          ></Box>
        </Stack>

        <Stack sx={{ paddingX: { xs: 2, sm: "6" } }} marginTop={4} spacing={6}>
          {/* 1 */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            {/* First part */}
            <Box sx={{ width: { xs: "100%", md: "50%" } }}>
              <Typography sx={{ maxWidth: "350px", fontWeight: "500" }}>
                Recevez, transférez et retirez de l’argent en votre devise
                préférée en toute sécurité.
              </Typography>

              <Stack spacing={2} mt={4}>
                <motion.div
                  initial={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      width: "fit-content",
                      borderRadius: "5px",
                      backgroundColor: "primary.main",
                      cursor: "pointer",
                      color: "white",
                      paddingY: 1,
                      paddingX: 2,
                    }}
                    alignItems="center"
                    spacing={1}
                  >
                    <DoneIcon />

                    <Typography sx={{ fontWeight: 500 }}>
                      <span style={{ fontWeight: 700 }}>XOF </span>
                      Wallet - Portemonnaie
                    </Typography>
                  </Stack>
                </motion.div>
                <Stack
                  direction="row"
                  sx={{
                    width: "fit-content",
                    borderRadius: "5px",
                    backgroundColor: "#F4F4F4",
                    cursor: "pointer",
                    color: "black",
                    paddingY: 1,
                    paddingX: 2,
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                  alignItems="center"
                  spacing={1}
                >
                  <DoneIcon />

                  <Typography sx={{ fontWeight: 500 }}>
                    <span style={{ fontWeight: 700 }}>NGN </span>
                    Wallet - Portemonnaie
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    width: "fit-content",
                    borderRadius: "5px",
                    backgroundColor: "#F4F4F4",
                    cursor: "pointer",
                    color: "black",
                    paddingY: 1,
                    paddingX: 2,
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                  alignItems="center"
                  spacing={1}
                >
                  <DoneIcon />

                  <Typography sx={{ fontWeight: 500 }}>
                    <span style={{ fontWeight: 700 }}>USDT </span>
                    Wallet - Portemonnaie
                  </Typography>
                </Stack>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ width: "fit-content" }}
                >
                  Comment ça marche ?
                </Button>
              </Stack>
            </Box>

            <Box
              sx={{
                width: { xs: "100%", md: "45%" },
                backgroundImage: `url("${Comment}")`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                minHeight: "392px",
              }}
            ></Box>
          </Box>

          {/* Epargne + */}

          <Stack direction={"row-reverse"} justifyContent="space-between">
            <Stack
              sx={{ textAlign: "left", width: { xs: "100%", sm: "50%" } }}
              spacing={2}
              alignItems="left"
            >
              <Stack spacing={0.5}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "primary.main",
                  }}
                >
                  Epargne +
                </Typography>
                <Box
                  sx={{
                    width: "49px",
                    height: "2px",
                    backgroundColor: "primary.main",
                  }}
                ></Box>
              </Stack>
              <Typography sx={{ maxWidth: "400px", fontWeight: "500" }}>
                Epargnez votre argent et retirez à tout moment souhaité sans
                aucun dérangement. Taux d’intérêt net supérieur à 3.75% (max
                taux brut banque) Intérêt dynamique sur crédit
              </Typography>

              <motion.div
                initial={{ opacity: 0 }}
                transition={{ duration: 1 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ width: "fit-content" }}
                >
                  En savoir plus
                </Button>
              </motion.div>
            </Stack>

            <Box
              sx={{
                width: "45%",
                display: { xs: "none", sm: "block" },
                position: "relative",
                height: "fit-content",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "110px 0px 0px 0px",
                  backgroundImage: `url("${Epargne}")`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  minHeight: "392px",
                  position: "absolute",
                  zIndex: 100,
                  top: 10,
                }}
              ></Box>
              <Box
                sx={{
                  borderRadius: "110px 0px 0px 0px",
                  minHeight: "392px",
                  backgroundColor: "primary.main",
                  boxShadow: "-2px -3px 6px 1px rgba(47, 47, 47, 0.25)",
                  position: "relative",
                  width: "100%",
                }}
              ></Box>
            </Box>
          </Stack>

          {/* Credit + */}
          <Stack direction={"row"} justifyContent="space-between">
            <Stack
              sx={{ textAlign: "left", width: { xs: "100%", sm: "50%" } }}
              spacing={2}
            >
              <Stack spacing={0.5}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "primary.main",
                  }}
                >
                  Credit
                </Typography>
                <Box
                  sx={{
                    width: "49px",
                    height: "2px",
                    backgroundColor: "primary.main",
                  }}
                ></Box>
              </Stack>
              <Typography sx={{ maxWidth: "400px", fontWeight: "500" }}>
                Nous avons pour soucis de faciliter le processus d’octroi de
                crédit aux particuliers solvables La microfinance numérique pour
                lancer l’économie
              </Typography>

              <motion.div
                initial={{ opacity: 0 }}
                transition={{ duration: 1 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ width: "fit-content" }}
                >
                  En savoir plus
                </Button>
              </motion.div>
            </Stack>

            <Box
              sx={{
                width: "45%",
                display: { xs: "none", sm: "block" },
                position: "relative",
                height: "fit-content",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "0px 110px  0px 0px",
                  backgroundImage: `url("${Credit}")`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  minHeight: "392px",
                  position: "absolute",
                  zIndex: 100,
                  top: 10,
                }}
              ></Box>
              <Box
                sx={{
                  borderRadius: "0px 110px  0px 0px",
                  minHeight: "392px",
                  backgroundColor: "primary.main",
                  boxShadow: "-2px -3px 6px 1px rgba(47, 47, 47, 0.25)",
                  position: "relative",
                  width: "100%",
                }}
              ></Box>
            </Box>
          </Stack>
        </Stack>
      </>

      <>
        <Stack alignItems={"center"} sx={{ marginTop: 4, textAlign: "center" }}>
          <Typography
            color="primary"
            sx={{
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            Avec Finanfa devenez un acteur formel de la microfinance
          </Typography>
          <Box
            sx={{
              width: "49px",
              height: "1px",
              backgroundColor: "primary.main",
            }}
          ></Box>
        </Stack>

        <Box
          sx={{
            width: "100%",
            marginTop: 4,
            display: "grid",
            justifyItems: "center",
            gridTemplateColumns: {
              xs: "repeat(auto-fit, minmax(300px, 1fr))",
              sm: "repeat(auto-fit, minmax(350px, 1fr))",
              md: "repeat(auto-fit, minmax(400px, 1fr))",
            },
            gap: 1,
          }}
        >
          {/* Image tontine */}

          <Box sx={{ width: "100%", height: "100%", objectFit: "cover" }}>
            <img
              src={Tontine}
              style={{ width: "100%", height: "100%" }}
              alt="tontine"
            />
          </Box>

          {/* Text tontine */}

          <Box
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              padding: 4,
              color: "white",
              background: "rgba(102, 71, 155, 0.95)",
            }}
          >
            <Stack sx={{ textAlign: "left" }} spacing={2}>
              <Stack spacing={0.5}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Tontine
                </Typography>
                <Box
                  sx={{
                    width: "49px",
                    height: "2px",
                    backgroundColor: "white",
                  }}
                ></Box>
              </Stack>
              <Typography sx={{ maxWidth: "500px" }}>
                Cotisation sécurisée. Achat de biens et services à votre rythme.
                Souscrivez désormais à vos tontine (Adogbè) depuis chez vous
                grâce à Finanfa.
              </Typography>

              <motion.div
                initial={{ opacity: 0 }}
                transition={{ duration: 1 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ width: "fit-content" }}
                >
                  En savoir plus
                </Button>
              </motion.div>
            </Stack>
          </Box>

          {/* text Service plus */}

          <Box
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              padding: 4,
              color: "white",
              background: "rgba(102, 71, 155, 0.95)",
            }}
          >
            <Stack sx={{ textAlign: "left" }} spacing={2}>
              <Stack spacing={0.5}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Service +
                </Typography>
                <Box
                  sx={{
                    width: "49px",
                    height: "2px",
                    backgroundColor: "white",
                  }}
                ></Box>
              </Stack>
              <Typography sx={{ maxWidth: "500px" }}>
                Réabonnement Canal+, Paiement facture d’électricité et d’eau,
                réservation, restaurant etc... Économisez du temps, de l’argent
                grâce à cette application.
              </Typography>

              <motion.div
                initial={{ opacity: 0 }}
                transition={{ duration: 1 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ width: "fit-content" }}
                >
                  En savoir plus
                </Button>
              </motion.div>
            </Stack>
          </Box>

          {/* Image service plus */}

          <Box sx={{ width: "100%", height: "100%", objectFit: "cover" }}>
            <img
              src={ServicePlus}
              style={{ width: "100%", height: "100%" }}
              alt="Service plus"
            />
          </Box>

          {/* Image boutique */}

          <Box sx={{ width: "100%", height: "100%", objectFit: "cover" }}>
            <img
              src={Boutique}
              style={{ width: "100%", height: "100%" }}
              alt="Boutique"
            />
          </Box>

          {/* Text boutique */}

          <Box
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              padding: 4,
              color: "white",
              background: "rgba(102, 71, 155, 0.95)",
            }}
          >
            <Stack sx={{ textAlign: "left" }} spacing={2}>
              <Stack spacing={0.5}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Boutique
                </Typography>
                <Box
                  sx={{
                    width: "49px",
                    height: "2px",
                    backgroundColor: "white",
                  }}
                ></Box>
              </Stack>
              <Typography sx={{ maxWidth: "500px" }}>
                Acceptez les paiements en toute sécurité depuis votre boutique
                ou e-boutique en utilisant Finanfa.
              </Typography>

              <motion.div
                initial={{ opacity: 0 }}
                transition={{ duration: 1 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ width: "fit-content" }}
                >
                  En savoir plus
                </Button>
              </motion.div>
            </Stack>
          </Box>
        </Box>
      </>

      <>
        <Stack alignItems={"center"} sx={{ marginTop: 4 }} spacing={1}>
          <Typography
            color="primary"
            sx={{
              width: "60%",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            Finanfa est utilisé par des dizaines de milliers d’utilisateurs
            fidèles répartis dans plus de 03 pays de l’Afrique
          </Typography>
          <Box
            sx={{
              width: "49px",
              height: "1px",
              backgroundColor: "primary.main",
            }}
          ></Box>
        </Stack>
        <Stack
          marginTop={4}
          sx={{
            paddingY: 2,
            width: "100%",
            height: "fit-content",
            backgroundImage: `url("${AccountsBack}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            color: "white",
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={4}
          >
            <Grid container sx={{ width: "60%" }}>
              <Grid item xs>
                <Stack spacing={1} sx={{ textAlign: "center", padding: 2 }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Comptes actifs
                  </Typography>
                  <Typography>+1322</Typography>
                </Stack>
              </Grid>

              <Grid item xs>
                <Stack spacing={1} sx={{ textAlign: "center", padding: 2 }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Agents actifs
                  </Typography>
                  <Typography>+1322</Typography>
                </Stack>
              </Grid>
              <Grid item xs>
                <Stack spacing={1} sx={{ textAlign: "center", padding: 2 }}>
                  <Typography sx={{ fontWeight: 500 }}>Agences</Typography>
                  <Typography>+1322</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </>

      <>
        <Stack alignItems={"center"} sx={{ marginTop: 4 }} spacing={1}>
          <Typography
            color="primary"
            sx={{
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            Nos Partenaires
          </Typography>
          <Box
            sx={{
              width: "49px",
              height: "1px",
              backgroundColor: "primary.main",
            }}
          ></Box>
        </Stack>

        <Stack
          sx={{ width: "100%", marginTop: 4, backgroundColor: "#FAFAFA" }}
          alignItems="center"
        >
          <Box
            sx={{
              width: "70%",
              display: "grid",
              justifyItems: "center",
              gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
              gap: 1,
            }}
          >
            <Stack
              justifyContent={"center"}
              alignItems="center"
              sx={{ width: "180px", height: "150px" }}
            >
              <img
                src={CceidLogo}
                alt="cceid Logo"
                style={{
                  maxWidth: "100%",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  height: "auto",
                }}
              />
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems="center"
              sx={{ width: "180px", height: "150px" }}
            >
              <img
                src={FriklabelLogo}
                alt="cceid Logo"
                style={{
                  maxWidth: "100%",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  height: "auto",
                }}
              />
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems="center"
              sx={{ width: "180px", height: "150px" }}
            >
              <img
                src={JenyLogo}
                alt="cceid Logo"
                style={{
                  maxWidth: "100%",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  height: "auto",
                }}
              />
            </Stack>
            <Stack
              justifyContent={"center"}
              alignItems="center"
              sx={{ width: "180px", height: "150px" }}
            >
              <img
                src={PosteLogo}
                alt="cceid Logo"
                style={{
                  maxWidth: "100%",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                  height: "auto",
                }}
              />
            </Stack>
          </Box>
        </Stack>
      </>
      <></>
    </>
  );
};

export default Home;
