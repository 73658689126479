import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
const HomeCard = ({ withColor, title, text }) => {
  return (
    <Stack
      spacing={2}
      sx={{
        width: "100%",
        textAlign: "center",

        borderRadius: "11px",
        backgroundColor: withColor ? "primary.main" : "#F7F7F7",
        padding: 5,
        color: withColor ? "white" : "black",
        boxShadow: withColor ? "" : "0px 0px 10px 1px rgba(77, 77, 77, 0.25)",
      }}
      alignItems="center"
    >
      <Stack alignItems={"center"} spacing={0.5}>
        <Typography
          sx={{ color: withColor ? "" : "primary.main", fontWeight: "600" }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            width: "49px",
            height: "1px",
            backgroundColor: withColor ? "white" : "primary.main",
          }}
        ></Box>
      </Stack>
      <Typography sx={{ color: withColor ? "" : "black", fontWeight: 500 }}>
        {text}
      </Typography>
    </Stack>
  );
};

export default HomeCard;
