import { Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

const AppLayout = () => {
  return (
    <Box>
      <Header />

      <Container>
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
};

export default AppLayout;
