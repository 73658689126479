import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { FinanFaLogo } from "../../assets/img";
import { Close, Dehaze } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { openExternal } from "../../utils/openExternal";

const Header = () => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const links = [
    {
      title: "Acceuil",
      active: false,
      link: "/",
    },
    {
      title: "Comment ça marche",
      active: false,
      link: "/comment-ça-marche",
    },
    {
      title: "Nos Agences ",
      active: false,
      link: "/nos-agences",
    },
    {
      title: "Contact",
      active: false,
      link: "/contact",
    },
  ];
  return (
    <AppBar
      sx={{ backgroundColor: "white", padding: { xs: 1, md: 2 } }}
      elevation={1}
      position={"sticky"}
    >
      {/* Desktop */}
      <Container>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          {/* Logo Part */}
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src={FinanFaLogo}
              style={{ width: "67px", height: "52px", objectFit: "cover" }}
              alt="Finanfa-Logo"
            />
            <Typography
              color={"primary"}
              sx={{
                fontWeight: 700,
                fontSize: { xs: "14px", sm: "18px" },
              }}
            >
              Finanfa
            </Typography>
          </Stack>

          {/* Links */}
          <Stack
            direction={"row"}
            spacing={4}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {links.map((el, index) => {
              return (
                <Stack
                  key={index}
                  alignItems="center"
                  spacing={0.5}
                  sx={{ minHeight: "30px" }}
                >
                  {/* <Link style={{ textDecoration: "none" }} to={el.link}> */}
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color:
                          location.pathname === el.link
                            ? "primary.main"
                            : "black",
                      }}
                    >
                      {el.title}
                    </Typography>
                  </Link>
                  <Box
                    sx={{
                      width: "50%",
                      height: "2px",
                      backgroundColor: "primary.main",
                      display: location.pathname === el.link ? "block" : "none",
                    }}
                  ></Box>
                </Stack>
              );
            })}
          </Stack>

          <IconButton
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={() => {
              setDrawerOpen(true);
            }}
          >
            <Dehaze />
          </IconButton>
          {/*  */}
          <Button
            color="secondary"
            variant="contained"
            sx={{ display: { xs: "none", md: "flex" } }}
            onClick={() => {
              openExternal(
                "https://play.google.com/store/apps/details?id=solutions.hardsoft.finanfa"
              );
            }}
          >
            Créer un compte
          </Button>
        </Stack>
      </Container>

      {/* Mobile version ---Drawer */}
      <Drawer
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        anchor="right"
      >
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            paddingX: 2,
            paddingY: 1,
            backgroundColor: "primary.main",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { xs: "14px", sm: "18px" },
              color: "white",
            }}
          >
            Finanfa
          </Typography>
          <IconButton
            onClick={() => {
              setDrawerOpen(false);
            }}
          >
            <Close sx={{ color: "white" }} />
          </IconButton>
        </Stack>
        <Box
          sx={{
            width: "90vw",
            maxWidth: "400px",
            minHeight: "100vh",
            height: "fit-content",
            paddingTop: 2,
          }}
        >
          {/* Links mobile */}
          <Stack direction={"column"} spacing={5} sx={{ padding: 4 }}>
            {links.map((el, index) => {
              return (
                <Stack
                  key={index}
                  alignItems="left"
                  spacing={0.5}
                  sx={{ minHeight: "30px" }}
                  onClick={() => {
                    setDrawerOpen(false);
                  }}
                >
                  {/* <Link style={{ textDecoration: "none" }} to={el.link}> */}
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color:
                          location.pathname === el.link
                            ? "primary.main"
                            : "black",
                      }}
                    >
                      {el.title}
                    </Typography>
                  </Link>
                  <Box
                    sx={{
                      width: "50px",
                      height: "2px",
                      backgroundColor: "primary.main",
                      display: location.pathname === el.link ? "block" : "none",
                    }}
                  ></Box>
                </Stack>
              );
            })}
            <Button
              sx={{ width: "fit-content", marginLeft: 2, marginTop: 10 }}
              color="secondary"
              variant="contained"
              onClick={() => {
                openExternal(
                  "https://play.google.com/store/apps/details?id=solutions.hardsoft.finanfa"
                );
              }}
            >
              Créer un compte
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Header;
