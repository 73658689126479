import {
  FacebookOutlined,
  Mail,
  Phone,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { FinanFaLogo } from "../../assets/img";
import { openExternal } from "../../utils/openExternal";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";

const Footer = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur " });

  const onSubmit = async (data) => {
    try {
      await emailjs.send("default_service", "template_id", data, "USER_ID");
      console.log("email sent");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Stack
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 4,
          padding: 4,
          backgroundColor: "#FAFAFA",
        }}
      >
        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },

            maxWidth: "1000px",
            width: "100%",
          }}
          direction="row"
        >
          <Stack
            justifyContent={"center"}
            sx={{
              textAlign: "center",
              padding: 5,
              color: "white",
              backgroundColor: "primary.main",
              maxWidth: { xs: "100%", sm: "300px" },
              width: "100%",
            }}
          >
            <Typography sx={{ marginBottom: 2 }}>
              Comment pouvons- nous vous aider?
            </Typography>
            <Stack direction={"row"} spacing={1} justifyContent="center">
              {/* Facebook Link */}
              <IconButton
                sx={{ color: "white" }}
                onClick={() => {
                  openExternal(
                    "https://www.facebook.com/lafinancefamiliale?mibextid=ZbWKwL"
                  );
                }}
              >
                <FacebookOutlined />
              </IconButton>

              {/* Whatsapp Link */}
              <IconButton
                sx={{ color: "white" }}
                onClick={() => {
                  openExternal();
                }}
              >
                <WhatsApp />
              </IconButton>
              {/* Youtube Link */}

              <IconButton
                sx={{ color: "white" }}
                onClick={() => {
                  openExternal("https://youtube.com/@finanfaofficiel7416");
                }}
              >
                <YouTube />
              </IconButton>
            </Stack>
          </Stack>
          <Box sx={{ padding: 3, width: "100%", flex: "stretch" }}>
            <form autoComplete="off" noValidate>
              <Stack spacing={2}>
                <TextField
                  type={"text"}
                  name="nom"
                  {...register("nom", { required: true })}
                  error={Boolean(errors.nom)}
                  helperText={errors.nom && "Le nom est obligatoire"}
                  label="Nom complet"
                  size="small"
                />
                <TextField
                  type={"text"}
                  name="email"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                  label="Email"
                  error={Boolean(errors.email)}
                  helperText={errors.email && "Adresse invalide"}
                  size="small"
                />
                <TextField
                  type={"text"}
                  name="telephone"
                  {...register("telephone", { required: true })}
                  error={Boolean(errors.telephone)}
                  helperText={
                    errors.telephone && "Le numero de telephone est obligatoire"
                  }
                  label="Telephone"
                  size="small"
                />
                <TextField
                  type={"text"}
                  name="message"
                  {...register("message", { required: true })}
                  label="Message"
                  error={Boolean(errors.message)}
                  helperText={errors.message && "Message obligatoire"}
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                />
                <Button
                  sx={{ width: "fit-content" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Envoyer
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Stack>

      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 4,
          padding: 4,
          backgroundColor: "primary.main",
          color: "white",
        }}
      >
        <Container>
          <Box
            sx={{
              display: "grid",

              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: 2,
            }}
          >
            {/* first */}
            <Stack direction={"column"} spacing={2}>
              <img
                src={FinanFaLogo}
                style={{ width: "67px", height: "52px", objectFit: "cover" }}
                alt="Finanfa-Logo"
              />

              <Typography>
                Finanfa votre plateforme intégré de services financiers
                digitaux: tontine, épargne+, portfeuille, credit, boutiques,
                assurance, services+, entreprises, sociétaires, partenaires.
              </Typography>
            </Stack>

            {/* second */}

            <Stack direction={"column"} spacing={1}>
              <Typography sx={{ fontWeight: "600" }}>
                Nos politiques de confidentialité
              </Typography>

              <Typography>
                <Link style={{ color: "white" }} to={""}>
                  Conditions générales
                </Link>
              </Typography>
            </Stack>

            {/* Third */}
            <Stack direction={"column"} spacing={1}>
              <Typography sx={{ fontWeight: "600" }}>Coordonnées</Typography>

              <Stack direction={"row"} spacing={1}>
                <Phone />
                <Typography>+229 6008 2727</Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Mail />
                <Typography>info@finanfa.africa</Typography>
              </Stack>
            </Stack>

            {/* fourth */}
            <Stack direction={"column"} spacing={1}>
              <Typography sx={{ fontWeight: "600" }}>Suivez-nous</Typography>
              <Stack direction={"row"} spacing={1}>
                {/* Facebook Link */}
                <IconButton
                  sx={{ color: "white" }}
                  onClick={() => {
                    openExternal(
                      "https://www.facebook.com/lafinancefamiliale?mibextid=ZbWKwL"
                    );
                  }}
                >
                  <FacebookOutlined />
                </IconButton>

                {/* Whatsapp Link */}
                <IconButton sx={{ color: "white" }}>
                  <WhatsApp />
                </IconButton>
                {/* Youtube Link */}

                <IconButton
                  sx={{ color: "white" }}
                  onClick={() => {
                    openExternal("https://youtube.com/@finanfaofficiel7416");
                  }}
                >
                  <YouTube />
                </IconButton>
              </Stack>
            </Stack>
          </Box>
          <Typography sx={{ textAlign: "center" }}>
            2022 finanfa.africa
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
